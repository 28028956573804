@import "../../../Styles/color.scss";
@import "../../../Styles/fonts.scss";
@import "../../../Styles/styleConstants.scss";
.modal-content {
  box-shadow: $box-shadow;
}

.product-description {
  .modal-dialog {
    max-width: 45em !important;
  }
}
.popup-modal__container {
  letter-spacing: normal;
  padding-right: 0em !important;

  .popup-modal__header {
    background-color: $pop-up-header-color;
    border-bottom: none;

    // .popup-icon::before {
    //   @extend .FontIcon;
    //   font-weight: 900;
    //   content: "\f058";
    //   margin-right: 0;
    //   padding-top: 0.7rem;
    // }

    .modal-title {
      @extend .FontBold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .added-container,
    .review-container,
    .completed-container,
    .rejected-container,
    .progress-container {
      position: relative;
      display: inline-block;
      margin-right: 20px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $secondary-color;
      color: #fff;
      padding: 0.25rem;
      box-shadow: $box-shadow;
      border-radius: 0.25em;
      z-index: 1;
      .added-title,
      .review-title,
      .completed-title,
      .rejected-title,
      .progress-title {
        text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
        @extend .FontBold;
        font-size: $font-size-medium;
      }
    }
    .review-container {
      background-color: $product-status-in-review-bg-color;
    }
    .completed-container {
      background-color: $product-status-is-completed-bg-color;
    }
    .rejected-container {
      background-color: $product-status-is-rejected-bg-color;
    }
    .progress-container {
      background-color: $product-status-is-rejected-bg-color;
    }
  }

  .popup-modal__body {
    color: $pop-up-text-color;
    background-color: $pop-up-bg-color;

    .popup-modal-message__title {
      @extend .FontRegular;
      font-size: $font-size-medium;
      margin-bottom: 1em;
    }

    .popup-modal-custom-message__title {
      @extend .popup-modal-message__title;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .popup-modal-message__sub-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 5em;
      padding: 0 0 1rem 0;
    }

    .popup-modal-message__description {
      @extend .FontRegular;
      font-size: $font-size-medium;
      margin-bottom: 1em;
    }

    .popup-modal-custom-message__description {
      @extend .popup-modal-message__description;
    }
    .form-subtitle {
      @extend .FontBold;
      font-size: $font-size-medium;
      text-align: left;
      margin-bottom: 1rem;
      color: #313e49;
    }
    .details-section {
      background-color: white;
      border-radius: 0.25em;
      padding: 1rem;
    }
    .details-section__item {
      justify-content: space-between;
      padding: 0.5rem;
    }
    .details-section__item:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }
    .details-section__item-label {
      @extend .FontBold;
      font-size: $font-size-small;
    }
    .details-section__item-description {
      @extend .FontRegular;
      font-size: $font-size-small;
      text-transform: capitalize;
    }
    .footnotes {
      margin-bottom: 0.25rem;
    }
  }

  //iframe body
  .iframe-popup-modal__body {
    .iframe-popup-modal__iframe {
      width: 100%;
      height: 39.4375em;
      display: block;
      border: none;
    }
  }

  .popup-modal-footer {
    border: none;
    background-color: $pop-up-bg-color;
    .popup-modal-footer__okBtn {
      background-color: $primary-btn-bg-color;
      border-radius: $button-radius-normal;
      color: $primary-btn-color;
      border-color: $primary-btn-border-color;
      @extend .FontBold;
      font-size: $font-size-medium;
      cursor: pointer;
      text-transform: uppercase;
      line-height: 1.62;
      padding: 0.76543em 2em;
      margin: auto;
    }
    .popup-modal-footer__okBtn:active,
    .popup-modal-footer__okBtn:hover {
      background-color: $primary-btn-hover-bg-color;
      border-color: $primary-btn-border-color;
      color: $primary-btn-color;
    }
    .popup-modal-footer__okBtn:focus {
      box-shadow: none;
    }
  }

  .iframe-popup-modal-footer {
    border: none;

    .iframe-popup-modal-footer__text {
      margin: auto;
      // padding: 0.5em 2em;
    }
  }
}
