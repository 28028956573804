@import "../../Styles/color.scss";
@import "../../Styles/fonts.scss";
@import "../../Styles/styleConstants.scss";

.upload-area-container {
  background-color: $global-nav-header-bg;
  padding: 1.25rem;
  border-radius: 0.25rem;
  text-align: center;
}

.upload-area {
  padding: 2rem;
  background-color: transparent;
  font-size: smaller;
  text-align: center;
  color: white;
  border-style: dashed;
  border-radius: 0.25rem;
}

.file-list-div {
  background-color: rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.74);
  margin: 10px;
  padding: 20px;
}

.file-container {
  color: rgba(0, 0, 0, 0.74);
  border: 1px solid;
  border-style: unset;
  text-align: center;
  padding: 5px;
}

.click-upload {
  display: none;
}
.click-link {
  text-decoration: underline;
  cursor: pointer;
  color: $primary-btn-bg-color;
}
.skip-button {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

//Confirm Upload Alert
#react-confirm-alert {
  .react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    .react-confirm-alert {
      text-align: center;
      padding: 20px;
      margin: auto;
      background-color: white;
      border-color: black;
      border: 1px solid;
      border-radius: 5px;
      box-shadow: black;
      .confirm-upload-savesubmit-btn {
        background-color: #00aeef;
        font-size: medium;
        width: 100%;
        padding: 10px;
        margin: auto;
      }
      .confirm-upload-cancel-btn {
        width: 100%;
        font-size: medium;
        padding: 10px;
        margin: auto;
      }
    }
  }
}
