@import "../../../Styles/color.scss";
@import "../../../Styles/fonts.scss";
@import "../../../Styles/styleConstants.scss";

.confirm-popup-modal__container {
  letter-spacing: normal;
  z-index: 999999;

  .confirm-popup-modal__header {
    background-color: $pop-up-header-color;
    border-bottom: none;
    .confirm-popup-modal-delete-icon::before {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f1f8";
      margin-right: 0.5rem;
    }
  }

  .confirm-popup-modal__body {
    padding-left: 2em;
    color: $pop-up-text-color;
    background-color: $pop-up-bg-color;

    .confirm-popup-modal-message__title {
      @extend .FontRegular;
      font-size: $font-size-medium;
      margin-bottom: 1em;
    }

    .confirm-popup-modal-message__description {
      @extend .FontRegular;
      font-size: $font-size-medium;
      line-height: 2;
    }
  }

  .confirm-popup-modal-footer {
    border: none;
    background-color: $pop-up-bg-color;
    justify-content: center;
    flex-wrap: nowrap;
    .confirm-popup-modal-footer__okBtn {
      background-color: $pop-up-button-color;
      border-radius: $button-radius-normal;
      color: $pop-up-btn-text-color;
    }
    .confirm-popup-modal-footer__okBtn:hover {
      background-color: $primary-btn-hover-bg-color;
    }
  }
}
