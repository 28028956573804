@import "./color.scss";
@import "./styleConstants.scss";
@import "./fonts.scss";

html {
  background-color: #f5f5f5;
  background: url("../Resources/Images/Homepage-bg-Pinnacle-Data-Systems.png")
    no-repeat center fixed;
  background-size: cover;
}

body {
  background-color: transparent !important;
  padding-top: 6.1rem;
  padding-bottom: 3.1rem;
}

.App {
  .link {
    font-size: $font-size-medium;
    color: $link-color;
    @extend .FontMedium;
    text-decoration: underline;
    cursor: pointer;
  }

  a,
  :visited {
    outline: 0 !important;
    outline: none !important;
  }
  :hover,
  :active,
  :focus,
  input:focus,
  .form-control:focus,
  input:-moz-focusring {
    outline: 0 !important;
    outline: none !important;
    border-color: transparent !important;
  }
  .btn.disabled,
  .btn:disabled,
  a.disabled,
  a:disabled {
    background-color: #000;
    opacity: 0.45;
  }
  .form-control:disabled,
  .form-control[readonly],
  .datepicker[readonly] {
    background-color: rgba(0, 0, 0, 0.1);
    color: #000;
    border: none;
  }
  .submit-btn.disabled:hover,
  .submit-btn:disabled:hover {
    background-color: #000;
  }
  .submit-btn {
    background-color: $primary-btn-bg-color;
    border-radius: $button-radius-normal;
    color: $primary-btn-color;
    border-color: $primary-btn-border-color;
    @extend .FontBold;
    font-size: $font-size-medium;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 1.62;
    padding: 0.76543em 2em;
  }
  .submit-btn:active,
  .submit-btn:hover {
    background-color: $primary-btn-hover-bg-color;
    border-color: $primary-btn-border-color;
    color: $primary-btn-color;
  }
  .submit-btn:focus {
    box-shadow: none;
  }
  //
  .secondary-submit-btn {
    line-height: 2.8;
    padding: 0px;
  }

  //
  .info-btn {
    background-color: $secondary-btn-bg-color;
    border: $border $secondary-btn-border-color;
    border-radius: $button-radius-normal;
    @extend .FontBold;
    font-size: $font-size-medium;
    color: $secondary-btn-color;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 1.62;
    padding: 0.76543em 2em;
    .fas::before {
      margin-right: 0.5rem;
    }
  }
  .info-btn:active,
  .info-btn:hover {
    background-color: #33393d;
    color: $secondary-btn-color;
  }
  .info-btn:focus {
    box-shadow: none;
  }
  .refresh {
    height: calc(1.75em + 0.75rem + 2px);
    width: 100%;
    margin: 0;
    padding: 0;
    .fas::before {
      margin-right: 0.5rem;
    }
  }
  input,
  .form-control {
    height: $input-height;
    border-radius: $input-radius;
    border: 1px solid transparent;
    border-bottom: 1px solid darkgray;
    font-size: $font-size-medium;
    color: #212529;
    text-transform: capitalize;
  }
  input::placeholder,
  .form-control::placeholder,
  input[type="email"],
  input[name="password"] {
    text-transform: none;
  }
  input[name="emailAddress"] {
    text-transform: lowercase;
  }
  input[name="downPaymentPercentage"] {
    padding-right: 0;
  }
  input:focus,
  .form-control:focus {
    border-color: orange;
    border: 1px solid darkgray;
    outline: none !important;
  }
  .stepper-form-title {
    @extend .FontBold;
    font-size: $font-size-large;
    text-align: left;
    color: #313e49;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .form-title {
    display: none;
    @extend .FontBold;
    font-size: $font-size-large;
    text-align: center;
    margin-bottom: 0.75rem;
    color: #313e49;
    padding-top: 0.8em;
  }
  .form-title-register,
  .form-title-signin,
  .form-title-verification,
  .form-title-password,
  .form-title-existing,
  .form-title-forgot-password,
  .form-title-terms {
    @extend .FontBold;
    font-size: $font-size-large;
    text-align: left;
    margin-bottom: 0.75rem;
    color: #313e49;
    padding: 1rem 2rem;
  }
  .form-subtitle {
    @extend .FontBold;
    font-size: $font-size-medium;
    text-align: left;
    margin-bottom: 1rem;
    color: #313e49;
  }
  .form-description {
    @extend .FontRegular;
    font-size: $font-size-medium;
    margin-bottom: 1rem;
  }
  .form-label {
    @extend .FontMedium;
    font-size: $font-size-small;
    margin-bottom: 0.5rem;
  }

  .error-msg {
    font-size: 80%;
    color: $error-text;
    display: flex;
    width: 100%;
    height: 2.6vh;
    text-transform: capitalize;
    span {
      text-transform: lowercase;
    }
  }

  .checkbox label {
    cursor: pointer;
    margin-bottom: 0;
  }
  input[type="checkbox"] {
    margin-right: 0.75rem;
    cursor: pointer;
  }
  .category-component .checkbox {
    margin-bottom: 0.75rem;
    margin-left: 1rem;
  }

  .invalid-input {
    border-color: $error-text !important;
  }
  .invalid-input:focus {
    border: 1px solid darkgray;
    border-bottom-width: 1px;
  }
  .select__dropdown {
    // CHRIS TO COME BACK TO AND CLEAN UP ALL OF SPRING's WHEEL RE-INVENTIONS
    // padding-right: 38px;
    // background-position: center right 17px;
    // background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0ICAgQzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjggICBzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiIGZpbGw9IiM2MDYwNjAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
    // background-repeat: no-repeat;
    // // background-size: 9px 9px;
    .dropdown__option {
      @extend .FontLight;
      color: $default-label;
    }
  }

  .divider {
    border: $border $divide-line-color;
    margin-bottom: $font-size-ultra-small;
    margin-top: $font-size-ultra-small;
  }

  // Add common style
  // basic icon rendering styles
  .icon::before {
    display: inline-block;
    margin-left: 0.2em;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .welcome-user-icon::before {
    @extend .FontIcon;
    font-weight: 900;
    content: "\f007";
  }

  // arrow-right arrow icon
  .product-navigation-icon::before {
    @extend .FontIcon;
    font-weight: 900;
    content: "\f061";
  }

  .product-remove-icon::before {
    @extend .FontIcon;
    font-weight: 900;
    content: "\f1f8";
    color: $vault-product-remove-icon;
  }

  //angle-left icon
  .navigation-bar-container__btn__icon::before {
    @extend .FontIcon;
    font-weight: 900;
    content: "\f104";
  }

  //lock icon
  .info-banner-container__icon::before {
    @extend .FontIcon;
    font-weight: 900;
    content: "\f023";
  }

  // check icon
  .check-icon {
    &:before {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f058";
      color: $validInputColor;
      padding-right: 1em;
    }
  }

  // input invalid icon
  .invalid-icon {
    &:before {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f057";
      color: $error-text;
      padding-right: 1em;
      mix-blend-mode: screen;
    }
  }

  .header-elipse-icon {
    background-image: url("../Resources/Images/menu.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 2.25em;
    height: 2.25em;
    cursor: pointer;
    transition: all 0.6s ease;
  }

  .show.dropdown .header-elipse-icon {
    background-image: url("../Resources/Images/menuOpened.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 3em;
    height: 4em;
    cursor: pointer;
    margin-top: -1rem;
    background-color: #313e49;
  }

  .breadcrumb-icon:last-child::after {
    margin: 0;
    @extend .FontIcon;
    content: "";
  }

  .breadcrumb-icon {
    &:after {
      margin: 0 0.5rem;
      @extend .FontIcon;
      font-weight: 900;
      content: "\f105";
    }
  }
  .loading-icon {
    &:before {
      @extend .FontIcon;
      content: "\f110";
      font-weight: 900;
      /* NOTE: Icon size has to be same as width/height defined by it's parent in order to keep it from looking like it is wandering around */
      font-size: 2.5em;
      color: #fff;
      margin: 0;
    }
    .fa-spin {
      animation: fa-spin 2s cubic-bezier(0.04, 0.84, 1, -0.13) infinite !important;
    }
  }
  .popup-icon {
    margin: auto 0;

    &::before {
      @extend .FontIcon;
      font-weight: 900;
      padding-right: 1em;
    }
  }

  .popup-modal-success-icon {
    &:before {
      content: "\f058";
    }
  }

  .popup-modal-failure-icon {
    &:before {
      content: "\f057";
      color: $error-text;
    }
  }

  .popup-modal-info-icon {
    &:before {
      content: "\f05a";
      color: $secondary-color;
    }
  }

  .scan-id-front__card__icon::before {
    @extend .FontBrand;
    font-weight: 900;
    content: "\f785";
    font-size: $font-size-medium;
    color: $scan-id-front-card-icon-color;
    margin-right: 0;
  }

  .terms-and-conditions__card__icon::before {
    @extend .FontBrand;
    font-weight: 900;
    content: "\f785";
    font-size: $font-size-medium;
    color: $terms-card-icon-color;
    margin-right: 0;
  }

  .user-icon::before {
    @extend .FontIcon;
    font-weight: 900;
    content: "\f007";
    font-size: $font-size-medium;
    color: $user-icon-color;
    margin-right: 0;
  }

  .user-selfie-icon {
    &:before {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f007";
      font-size: $font-size-super-large;
    }
  }
  .thank-you-container {
    text-align: center;
  }

  //custom radios
  .custom-radios {
    .btn {
      display: block;
      width: 100%;
      min-height: 2.5rem;
      padding: 0.375rem 0.75rem;
      margin-bottom: 1rem;
      border-radius: $custom-radio-radius;
    }
    .custom-radio-unselected,
    .custom-radio-unselected[disabled]:hover {
      background: $custom-radio-unselected-bg-color;
      box-shadow: $custom-radio-box-shadow;
      border: 1px solid $custom-radio-unselected-border-color;
      color: $custom-radio-unselected-color;
      background-color: white;
      // CHRIS: DEMO HACK
      border-radius: 0.25em;
      box-shadow: 1px 1px 3px #c5c7cb, 0px 1px 6px #f1f3f9;
      color: #06688b;
      text-align: center;
    }
    .custom-radio-unselected:hover,
    .product-list .btn.custom-radio-unselected:hover {
      background-color: #35566d;
      color: white;
      @extend .FontMedium;
      font-size: $font-size-medium;
    }
    .custom-radio-selected:hover {
      color: white;
    }
    .custom-radio-selected,
    .custom-radio-unselected[disabled],
    .custom-radio-unselected[disabled]:hover {
      background-color: $custom-radio-selected-bg-color;
      box-shadow: $custom-radio-box-shadow-inset;
      border: 1px solid $custom-radio-selected-border-color;
      color: $custom-radio-selected-color;
      @extend .FontMedium;
      font-size: $font-size-medium;
    }
    .product-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      grid-auto-rows: minmax(100px);

      .account-label {
        @extend .FontRegular;
        font-size: $font-size-small;
        padding: 0;
        margin: 0;
      }

      .account-amount {
        @extend .FontBold;
        font-size: $font-size-medium;
        padding-bottom: 0.5rem;
      }

      .account-num {
        @extend .FontRegular;
        font-size: $font-size-small;
        padding: 0;
        margin: 0;
      }

      .btn[value] {
        padding: 4.25rem 0.5rem 0.5rem 0.5rem;
        height: auto;
        background-color: $custom-radio-unselected-bg-color;
        border-radius: $custom-radio-radius;
        box-shadow: $custom-radio-box-shadow;
        border: 1px solid rgba(0, 0, 0, 0.125);
        color: #06688b;
        text-align: center;
        @extend .FontRegular;
        font-size: $font-size-medium;
        position: relative;
      }
      .btn[value]::before {
        @extend .FontIcon;
        font-weight: 900;
        font-size: 3em;
        color: $primary-btn-bg-color;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
      }
      .btn[value="11"]::before {
        // car icon
        content: "\f5e4";
      }
      .btn[value="21"]::before {
        // shopping bag icon
        content: "\f290";
      }
      .btn[value="22"]::before {
        // plane take-off icon
        content: "\f5b0";
      }
      .btn[value="25"]::before {
        // file-invoice icon
        content: "\f570";
      }
      .btn[value="27"]::before {
        // piggy bank icon
        content: "\f4d3";
      }
      .btn[value="28"]::before {
        // network or consolidation icon
        content: "\f542";
      }
      .btn[value="33"]::before {
        // education icon
        content: "\f19d";
      }
      .btn[value="41"]::before {
        // repairs icon
        content: "\f7d9";
      }
      .btn[value="50"]::before {
        content: "\f4c4";
        // handshake icon
      }
      .btn[value="51"]::before {
        // house icon
        content: "\f015";
      }
      .btn[value="60"]::before {
        // home with laptop
        content: "\e066";
        // switch
        // content: "\f079";
      }
      .btn[value="61"]::before {
        // file signature icon
        content: "\f573";
      }
      .btn[value="62"]::before {
        // couch furniture icon
        content: "\f4b8";
      }
      .btn[value="88"]::before {
        // hand holding cash icon
        content: "\f4c0";
      }
      .btn[value="89"]::before {
        // health icon
        content: "\f21e";
      }
      .btn[value="90"]::before {
        // more... icon
        content: "\f141";
      }
      .btn[value="chequing"]::before {
        // checking account
        content: "\f53d";
      }
      .btn[value="savings"]::before {
        // savings account
        content: "\f4d3";
      }
      .btn[value="otherFi"]::before {
        // savings account
        content: "\f19c";
      }
      .btn[disabled],
      .btn[disabled]:hover {
        background-color: $custom-radio-selected-bg-color;
        box-shadow: $custom-radio-box-shadow-inset;
        border: 1px solid $custom-radio-selected-border-color;
        color: $custom-radio-selected-color;
        opacity: 1;
      }
      .btn[disabled]::before {
        color: $custom-radio-selected-color;
      }
      .btn.custom-radio-unselected:hover {
        border-color: white;
      }
      .btn[value]:hover::before {
        color: white;
      }
      .custom-radio-selected,
      .custom-radio-unselected[disabled],
      .custom-radio-unselected[disabled]:hover {
        background-color: $custom-radio-selected-bg-color;
        box-shadow: $custom-radio-box-shadow-inset;
        border: 1px solid $custom-radio-selected-border-color;
        color: $custom-radio-selected-color;
        @extend .FontMedium;
        font-size: $font-size-medium;
      }
      .custom-radio-unselected.logo,
      .custom-radio-unselected.logo:hover {
        padding: 2.5rem 0rem 0.5rem 0rem;
      }
    }
  }

  header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    .header-container {
      background-color: $header-bg-color;

      .header-row {
        position: relative;
        box-shadow: $header-box-shadow;
        padding: 0.5rem;
      }
      .appVersion {
        float: right;
        font-size: 8px;
      }

      .page-header {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        @extend .FontBold;
        font-size: $font-size-larger;
        font-weight: 900;
        text-transform: uppercase;
      }

      .header-container__item {
        height: 100%;
      }

      .welcome-user-text {
        @extend .FontRegular;
        font-size: $font-size-medium;
        color: $welcome-user-text-color;
      }

      .vault-icon-container {
        .vault-circle {
          background-color: $vault-header-icon-bg-color;
          color: $vault-header-icon-color;
          display: flex;
          flex-direction: row;
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          position: relative;
          top: -0.75rem;
          right: -2.75rem;
          justify-content: center;
          border: 2px solid #fff;
          -webkit-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

          span.item-count {
            position: relative;
            top: -2px;
          }
        }
        // CHRIS NOTE: HACKS INPLACE TO SUPPORT EXISTING GLOBAL NAV BAR. WILL REFACTOR TO SUPPORT BUTTON GROUP
        .vault-image,
        .fund-account-image {
          background-image: url("../Resources/Images/vaultIconFull.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 2.25em;
          height: 2.25em;
          cursor: pointer;
          transition: all 0.6s ease;
        }
        .vault-image.opened,
        .fund-account-image.opened {
          background-image: url("../Resources/Images/vaultIconFullOpened.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 3em;
          height: 4em;
          cursor: pointer;
          margin-top: -1rem;
          background-color: $vault-header-bg-color;
        }
        .fund-account-image {
          background-image: url("../Resources/Images/fundAccount.svg");
        }
        .fund-account-image.opened {
          background-image: url("../Resources/Images/fundAccountOpened.svg");
        }
        .header-elipse-icon {
          margin-left: 1rem;
        }
      }
    }

    .breadcrumb-container {
      // display: none;
      width: 100%;
      background-color: $breadcrumb-bg-color;
      box-shadow: $header-box-shadow;
      .breadcrumb {
        background-color: $breadcrumb-bg-color;
        @extend .FontRegular;
        font-size: $font-size-small;
        margin-bottom: 0rem;
        padding: 0.5rem 1rem;

        a {
          @extend .link;
          text-decoration: none;
          cursor: pointer;
        }

        .active {
          color: $active-text-color;
        }
      }
    }
  }

  .logo {
    background-image: url("../Resources/Images/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 10em;
    height: 3em;
  }
  .custom-radio-unselected.logo {
    background-image: url("../Resources/Images/logo.svg");
    background-repeat: no-repeat;
    background-position: 0.25rem 0.25rem;
    background-size: 4em;
    &:hover {
      background-image: url("../Resources/Images/logo-reverse.svg");
    }
  }
  .custom-radio-unselected.logo[disabled] {
    background-image: url("../Resources/Images/logo-reverse.svg");
    &:hover {
      background-image: url("../Resources/Images/logo-reverse.svg");
      background-repeat: no-repeat;
      background-position: 0.25rem 0.25rem;
      background-size: 4em;
    }
  }

  .info-banner {
    background-color: $info-banner-bg;
    border: $border $info-banner-border;
    color: $info-banner-text-color;

    .info-banner__lock-icon::before {
      @extend .FontIcon;
      font-weight: 600;
      content: "\f023";
      font-size: 1.375em;
      margin-right: 0;
    }

    .info-banner__text {
      @extend .FontRegular;
      font-size: $font-size-small;
    }
  }
  .welcome-back {
    min-height: 3rem;
    height: auto;
    background-color: $welcomeBack-banner-bg;
    border: none;
    .info-banner__text {
      color: $info-banner-text-color;
      font-size: $font-size-medium;
      @extend .FontBold;
      padding: 1rem;
    }
  }
  .lock-icon::before {
    @extend .FontIcon;
    font-weight: 600;
    content: "\f023";
    margin-right: 0.5em;
  }

  .navigation-bar {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    .navigation-bar__back-btn {
      background-color: $navigation-btn-bg-color;
      border-radius: $navigation-button-radius;
      .navigation-bar__back-btn__icon::before {
        @extend .FontIcon;
        font-weight: 600;
        content: "\f104";
        font-size: 1.1875em;
        color: $navigation-btn-icon-color;
      }

      .navigation-bar__back-btn__text {
        @extend .FontBold;
        font-size: $font-size-extra-small;
        display: block;
        color: $navigation-btn-icon-color;
      }
    }
    .navigation-bar__back-btn:active,
    .navigation-bar__back-btn:hover {
      background-color: $primary-btn-hover-bg-color;
      color: #fff;
      border: 1px solid #fff;
    }
  }

  .form-container {
    padding: 1rem 2rem;
  }

  .content-wrapper {
    background-color: transparent;
  }
  .main-container {
    padding-top: 0rem;

    .main-container__body__left-column.tri-column,
    .main-container__body__left-column.bi-column {
      background-color: transparent;
      padding-right: 0;
      > * {
        background-color: rgba(255, 255, 255, 0.85);
        border: $border $primary-container-border;
        border-radius: 0.5rem 0 0 0.5rem;
        box-shadow: $box-shadow;
        height: 100%;
        padding: 1rem;
      }

      // CHRIS BUG

      .forgot-link {
        text-align: right;
      }
    }
  }

  .main-container__body__middle-column {
    background-color: transparent;
    > * {
      background-color: rgba(255, 255, 255, 0.85);
      border: 1px solid #c1c4c4;
      border-radius: 0 0 0 0;
      box-shadow: $box-shadow;
      height: 100%;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info-title {
        font-size: $font-size-large;
        @extend .FontMedium;
        margin-bottom: 2rem;
        text-align: center;
        color: #313e49;
      }
      .info-message {
        @extend .form-description;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .main-container__body__right-column {
    padding: 0 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.small-vault-container {
  z-index: 1030;
  position: relative;
  width: 100%;
  margin-top: -0.5rem;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
}

.vault-container,
.dropdown-menu.show {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0.5rem 0.5rem 0;
  box-shadow: $box-shadow;
  padding-bottom: 2rem;
  height: 100%;
  .vault-container__inner-container {
    position: sticky;
    top: 6.2rem;
  }
  .vault-header__container {
    display: flex;
    align-items: center;
    border-radius: 0 0.5rem 0rem 0;
    background-color: $vault-header-bg-color;
    padding: 1rem;

    h4.form-subtitle {
      @extend .FontMedium;
      font-size: 1.25em;
      text-align: left;
      margin-bottom: 0rem;
      color: $vault-header-color;
      width: 100%;
      .fa-shopping-cart,
      .fa-money-bill-wave,
      .fa-bars {
        margin-right: 0.5rem;
      }
      .close {
        color: $vault-header-color;
        margin-right: 0.5rem;
      }
    }
  }

  .proceed-btn__container {
    display: flex;
    .submit-btn.btn {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      font-size: $font-size-small;
    }
  }
  .vault-container__card_bg-color {
    background-color: $vault-card-bg-color;
  }
  .vault-container__card.active-vault-container__card_bg-color {
    background-color: $product-status-in-progress-bg-color;
    border: none;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 1);
    margin-bottom: 1.5rem;
  }
  .vault-container__card {
    border-radius: 0.25rem;
    box-shadow: 0px 0px 2px rgba(54, 54, 54, 0.3);

    .vault-container__card__body {
      width: 100%;
      .h5 {
        @extend .FontBold;
        font-size: $font-size-medium;
      }
      .vault-container__card__body__title_color {
        color: rgba(3, 72, 131, 0.73);
      }
      .active-container__card_color {
        color: $fund-product-card-text-color;
      }
      .vault-container__card__body__title {
        @extend .FontBold;
        font-size: $font-size-medium;
      }
      .vault-container__card__body__subtitle_color {
        color: $vault-card-text-color;
      }
      .vault-container__card__body__subtitle {
        font-size: 0.6875em;
        @extend .FontRegular;
      }
      .vault-container__card__body__link {
        font-size: $font-size-small;
      }
      .fa-trash {
        cursor: pointer;
        position: revert;
        margin-left: 0.5rem;
        height: $font-size-large;
        font-size: $font-size-small;
      }
      .fa-trash.active-container__card_color {
        padding: 0.25rem;
        background-color: #fff;
        border-radius: 0.25rem;
        color: $secondary-color;
      }
    }
  }
}
.dropdown-menu.show {
  height: revert;
}

.dropdown-menu.show.global_nav_drawer {
  .dropdown-header {
    @extend .FontBold;
    padding-left: 1rem;
    font-size: 1em;
    text-align: left;
    color: #313e49;
  }
  .dropdown-divider {
    margin-top: 0;
  }
  .dropdown-item {
    padding: 1rem 1.5rem 1rem 2rem;
  }
  .icon {
    float: right;
  }
}
.dropdown-menu.show.global_nav_drawer::-webkit-scrollbar,
.vault-container::-webkit-scrollbar {
  width: 0px;
}

.footer {
  display: none;

  background-color: $footer-bg-color;
  @extend .FontLight;

  .footer-container__item__text {
    color: $footer-item-text-color;
    font-size: $font-size-extra-small;
  }

  .footer__chat-text {
    color: $footer-text-color;
    font-size: $font-size-medium;
  }

  .footer__chat-icon {
    background-image: url("../Resources/Images/chatIcon.svg");
    height: 2.6875em;
    background-repeat: no-repeat;
  }
}

.password-input {
  position: relative;
  .password-input_eye-icon::before {
    @extend .FontIcon;
    cursor: pointer;
    font-weight: 900;
    content: "\f06e";
    font-size: 1.0625em;
    color: $password-show-icon-color;
  }

  .password-input_eye-icon-close::before {
    @extend .FontIcon;
    cursor: pointer;
    font-weight: 900;
    content: "\f070";
    font-size: 1.0625em;
    color: $password-hide-icon-color;
  }
}

.register__info-container {
  .register__info-container__message {
    font-weight: 300;
    font-size: $font-size-large;
    @extend .FontLight;
  }
}

.verification-code__info-container {
  .verification-code__info-container__title {
    @extend .FontMedium;
    font-size: $font-size-medium;
  }
  .verification-code__info-container__message {
    @extend .FontLight;
    font-size: $font-size-small;
  }
  .verification-code__info-container__recive-code-question {
    @extend .FontMedium;
    font-size: $font-size-medium;
  }
}

.progress-indicator-container {
  top: 30%;
  width: 2.5rem;
  height: 2.5em;
  position: sticky;
  z-index: 1050;
  margin-left: auto;
  margin-right: auto;
}

.verification-code-container_my-vault {
  background-color: $secondary-color;
}

// Progressive Disclosure Drawers

.drawer-content-container {
  position: relative;
  z-index: 0;
  padding: 1rem;
  background: rgba(121, 134, 144, 0.25);
  //box-shadow: $drawer-content-container-box-shadow;
  border: 1px solid #ffffff;
  color: $default-label;
  .error-msg {
    color: #a60000;
  }
  .custom-radios {
    .custom-radio-unselected {
      box-shadow: $drawer-custom-radio-box-shadow;
    }
    .custom-radio-selected {
      box-shadow: $drawer-custom-radio-box-shadow-inset;
      border-color: #1d2f3b;
    }
  }

  .multiple-entry__container {
    background-color: rgba(69, 70, 80, 0.4);
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: $custom-radio-radius;
    .divider {
      border: none;
    }
  }

  .info-title {
    @extend .FontBold;
    font-size: $font-size-medium;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    .fa-trash {
      right: 1rem;
      position: absolute;
      top: auto;
      padding: 0.25rem;
      background-color: #fff;
      border-radius: 0.25rem;
      border: none;
    }
  }

  .form-label {
    font-size: $font-size-medium;
    @extend .FontRegular;
  }

  .drawer-content-container__title {
    @extend .FontMedium;
    margin-bottom: 0.5em;
  }

  .validations-rules-wrapper {
    @extend .FontRegular;
    font-size: $font-size-small;

    .set-password-validations-item {
      font-size: $font-size-small;
      margin-bottom: 0.5em;
    }
  }
}

.drawer-content-container {
  padding: 0.5rem 1rem 1rem;
  .form-control {
    background-color: rgba(255, 255, 255, 0.75);
    color: #000;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  input::placeholder {
    color: rgb(112, 112, 112);
  }
  input:focus {
    background-color: #fff;
  }
  input:focus::placeholder {
    color: rgb(150, 150, 150);
  }
  .form-control:focus-visible {
    background-color: #fff;
  }
  input[name="insuranceAmount"],
  input[name="insuranceAmount"]:focus,
  input[name="insuranceAmount"]:active {
    background-color: transparent;
    outline: none;
    color: #000;
    outline-width: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid darkgray;
  }
}

//   password information block styles

.set-vault-password-info-container {
  height: auto;
  padding: 1em;
  border: $border $secondary-container-border;
  background-color: $secondary-bg-color;
  @extend .FontLight;

  .set-vault-password-info-description {
    font-size: $font-size-small;
  }

  .set-vault-password-info-title {
    font-size: $font-size-medium;
    @extend .FontMedium;
  }
}

.fund-account__container {
  background-color: $fund-account-bg-color;
  border: $border $fund-account-border-color;

  .fund-account-header {
    background-color: #313e49;
    background-repeat: no-repeat;
    background-position: 1em;
    color: $fund-account-header;
    font-size: 1.25em;
    display: flex;

    .header-title {
      @extend .FontLight;
      font-size: $font-size-medium;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $fund-account-heading-color;
    }
    .long-left-arrow::before {
      @extend .FontIcon;
      font-weight: 900;
      font-size: 0.6em;
      content: "\f060";
      position: absolute;
      top: 1.6em;
      left: 2.5em;
    }
    .long-right-arrow::before {
      @extend .FontIcon;
      font-weight: 900;
      font-size: 0.6em;
      content: "\f061";
      position: absolute;
      top: 3em;
      left: 4.9em;
    }
    .dollor-icon::before {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f155";
    }
    .fund-account-header-text {
      font-size: 1.25em;
      color: $fund-account-heading-color;
    }
  }

  .fund-account-item__wrapper {
    .fund-account-item {
      font-size: 0.6em;
      font-weight: 600;
    }
  }
}

// common styles
.icon::before {
  display: inline-block;
  margin-left: 0.2em;
  margin-right: 0.2em;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.employment-and-income {
  .employment-and-income__subtitle {
    @extend .FontMedium;
    font-size: $font-size-medium;
  }
}

.stepper {
  margin-bottom: 2rem;
  .step {
    border: 1px solid;
    border-radius: 100px;
    border-color: transparent;
    width: 20px;
    height: 20px;
  }

  .step-inactive {
    background-color: rgba(0, 0, 0, 0.1);
    @extend .FontLight;
    font-size: $font-size-medium;
  }

  .step-active {
    background-color: $stepper-active-bg-color;
    @extend .FontBold;
    font-size: $font-size-medium;
    color: #fff;
  }

  .step-complete {
    background-color: $card-added-tick-mark;
  }

  .step-complete::before {
    @extend .FontIcon;
    content: "\f00c";
    font-size: 0.9em;
    font-weight: 600;
    color: #fff;
  }

  .step-text {
    @extend .FontBold;
    font-size: $font-size-ultra-small;
  }

  hr {
    border-style: solid;
    border-color: $stepper-line-color;
  }
}

.mobile-stepper-container {
  margin: 0 0rem 1rem 0;
  .mobile-stepper {
    max-width: 4em;
    padding-right: 1rem;
    @extend .FontBold;
    .CircularProgressbar .CircularProgressbar-path {
      stroke-linecap: butt;
    }
    .CircularProgressbar .CircularProgressbar-trail {
      stroke: none;
    }
  }
}

.main-container {
  .main-container__body__left-column.bi-column {
    padding-right: 15px;
  }
}

.info-tile-container {
  padding: 1rem;
  position: relative;
  width: 100%;
}
.info-tile {
  height: 100%;
  background-color: $terms-card-bg-color;
  border-radius: $custom-radio-radius;
  text-align: center;
  border: 2px solid $terms-card-border-color;
  padding: 1rem;
}
// .info-tile__header {placeholder in case of future FI}
.info-tile__icon {
  @extend .FontBold;
  font-size: $font-size-larger;
  color: $terms-card-icon-color;
}
.info-tile__title {
  @extend .FontBold;
  font-size: $font-size-medium;
  color: $terms-card-title-color;
}
.info-tile__description {
  font-size: $font-size-medium;
  color: $terms-card-description-color;
  margin-bottom: 1rem;
}
.terms-and-conditions__links-container {
  background-color: transparent;
  ul {
    margin-left: auto;
    margin-right: auto;
  }
  .link {
    margin-bottom: 1rem;
    list-style-type: none;
  }
}
.getting-started-container {
  // hack as for some reason the info tiles are coded to use the same component as the progressive disclosure drawers???
  .info-tile-container {
    padding: 0;
  }
  .info-tile {
    height: 100%;
    background-color: $transparent;
    border-radius: none;
    text-align: left;
    border: 0px solid $terms-card-border-color;
    padding: 0;
  }
}

.Compliance {
  .info-button {
    color: $primary-btn-bg-color;
  }
}

.btn.retake {
  border: none;
  font-size: 1em;
  color: #2185d0;
  padding: 0;
  margin: 0;
  @extend .FontMedium;
  text-align: left;
}
.btn.retake[disabled] {
  color: grey;
}
.scan-id__card,
.e-sig {
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  background-color: $custom-radio-unselected-bg-color;
  border: 1px solid $secondary-color;
  border-radius: $custom-radio-radius;
  box-shadow: $custom-radio-box-shadow;
  color: $secondary-color;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.e-sig {
  // border: 1px solid $primary-color;
  // color: $primary-color;
  @extend .FontBold;
  font-size: $font-size-medium;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.62;
}
.scan-id__card i.fas,
.e-sig i.fas {
  font-size: 3em;
  color: $secondary-color;
}
.e-sig:hover {
  i.fas {
    color: white;
  }
  .e-sig__text {
    color: white;
  }
}
.e-sig:active,
.e-sig:focus {
  outline: none;
  outline-color: transparent;
  outline-width: 0px;
}
.scan-id__card__img {
  width: 100%;
  height: auto;
}
.scan-id__text,
.e-sig__text {
  padding: 0.5rem;
  color: $secondary-color;
}
// .e-sig__text {
//   color: $primary-color;
// }
.e-sig:hover,
.e-sig:active {
  background-color: $primary-btn-hover-bg-color;
  border-color: $primary-btn-border-color;
  color: $primary-btn-color;
}
img.selfie-image {
  width: 100%;
  height: auto;
}
.alert i {
  margin-right: 10px;
}
.mb-3 .alert i {
  align-self: center;
}
// .alert span{
//     // placeholder for future FI text styling
// }
.alert {
  p {
    font-size: $font-size-medium;
  }
  .alert-heading {
    font-size: $font-size-large;
    font-weight: 700;
    padding: 0.5rem 0;
  }
  ul {
    margin: 1rem 0.5rem 0.5rem;
  }
  li {
    font-weight: 700;
    padding-bottom: 0.5rem;
  }
}
.alert-info {
  li {
    font-weight: 500;
  }
}

.scan-error__card {
  @extend .FontRegular;
  border: $border #bebebe;
  border-radius: 0;

  .info-row {
    font-size: $font-size-small;
  }
}

.review-request {
  @extend .FontMedium;
  font-size: $font-size-small;
}

.confirm-question {
  .confirm-question__label {
    @extend .FontLight;
    font-size: $font-size-medium;
  }
}

.selfie-container {
  justify-content: center;
  text-align: center;
}

.fund-method-container {
  .fund-method__body {
    .fund-method__body__title {
      line-height: 1.14;
    }
  }
}

.postalCode,
.form-control.postalCode {
  text-transform: uppercase;
}
.postalCode::placeholder {
  text-transform: none;
}

.fund-amount-container {
  .fund-amount__body {
    .fund-amount__body__title {
      @extend .FontLight;
      font-size: $font-size-medium;
      line-height: 1.14;
      font-weight: 600;
    }
  }
}

.common-edit-btn_color {
  color: $secondary-color !important;
}

.common-edit-btn {
  padding: 0.25rem;
  background-color: #fff;
  border-radius: 0.25rem;
  border: none;
  .edit-icon::before {
    @extend .FontIcon;
    content: "\f044";
    font-weight: bold;
  }
  .edit-btn__label {
    // SCREENREADER HACK
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    // END SCREENREADER HACK
  }
}

.confirm-account-container,
.vault-container,
.income-details-container,
.loan-details-container,
.uploader-details-container,
.joint-application-status-container {
  .details-section {
    background-color: white;
    border-radius: 0.25em;
    padding: 1rem;
  }
  h3 {
    @extend .FontBold;
    font-size: $font-size-medium;
  }
  .edit-btn,
  .trash-btn,
  .resend-btn {
    color: $secondary-color;
    padding: 0.25rem;
    background-color: #fff;
    border-radius: 0.25rem;
    border: none;
    .edit-icon::before {
      @extend .FontIcon;
      content: "\f044";
      font-weight: bold;
    }
    .trash-icon::before {
      @extend .FontIcon;
      content: "\f1f8";
      font-weight: bold;
    }
    .resend-icon::before {
      @extend .FontIcon;
      content: "\f1d8";
      font-weight: bold;
    }
    .edit-btn__label,
    .trash-btn__label,
    .resend-btn__label {
      // SCREENREADER HACK
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      // END SCREENREADER HACK
    }
  }
  .btn-group {
    .edit-btn,
    .trash-btn,
    .resend-btn {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .details-section__header {
    h3 {
      margin-bottom: 1em;
    }
  }

  .details-section__item {
    padding: 0.5rem;
    width: 95%;
    margin-left: 0.5rem;
  }
  .details-section__item:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .details-section__item-label {
    @extend .FontBold;
    font-size: $font-size-small;
    .form-label {
      margin-bottom: 0;
    }
    input.form-control {
      padding: 0;
      margin: 0;
      background-color: transparent;
      color: black;
      border: none;
    }
  }
  .details-section__item-description {
    @extend .FontRegular;
    font-size: $font-size-small;
    text-transform: capitalize;
    .form-label {
      margin-bottom: 0;
    }
    input.form-control {
      padding: 0;
      margin: 0;
      background-color: transparent;
      color: black;
      border: none;
    }
  }
  .email {
    text-transform: none !important;
  }
  .alert {
    display: block;
  }
  .mb-3 .alert i {
    align-self: auto;
    line-height: inherit;
  }
}

.joint-application-status-container {
  .details-section {
    margin-bottom: 1rem;
  }
  .details-section:last-of-type {
    margin-bottom: 0;
  }
  .alert.alert-info {
    height: 100%;
  }
}
.loan-details-container {
  .details-section__item {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
  }
}
.uploader-details-container {
  .details-section__item {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem;
    .details-section__item-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.income-details-container {
  .fa-trash {
    position: revert;
  }
  .details-section__item {
    width: 100%;
  }
}

.vault-container {
  .details-section__item {
    padding: 0.5rem 0;
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
  }
}
.funding {
  .details-section__item {
    .col-sm-6 {
      padding-left: 0;
    }
  }
  .details-section__item-label {
    @extend .FontBold;
    font-size: $font-size-medium;
  }
  .details-section__item-description {
    @extend .FontRegular;
    font-size: $font-size-medium;
  }
  .amount {
    font-size: $font-size-large;
    word-wrap: break-word;
  }
}
.applicant-name {
  text-transform: uppercase;
}

.confirm-details-request {
  font-size: 1.125em;
  @extend .FontMedium;
}
.confirm-details-request__description {
  font-size: $font-size-medium;
  @extend .FontLight;
}

.confirm-details__footer {
  background-color: transparent;

  .confirm-account-opening {
    @extend .FontMedium;
  }

  .confirm-details-notice-wrapper {
    .confirm-account-opening {
      font-weight: 500;
      font-size: $font-size-medium;
    }
    .confirm-account-opening-question {
      font-weight: 500;
      font-size: $font-size-medium;
      @extend .FontLight;
    }
  }
}

// CHRIS: Marketplace Banner
.fi-brand__container {
  background: linear-gradient(
      to right,
      rgba(32, 3, 100, 0.836),
      rgba(34, 185, 231, 0.678)
    ),
    url("../Resources/Images//online-banking.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center left 40%;
  color: white !important;
  height: 420px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem !important;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgb(162, 119, 152);
}
.fi-brand__logo {
  background-image: url("../Resources/Images/logo.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: none;
}
.fi-brand__tagline-container {
  width: 50%;
  .tagline__title-1 {
    @extend .FontMedium;
    font-size: $font-size-super-large;
    line-height: 1.1;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1);
  }
  .tagline__title-2 {
    @extend .tagline__title-1;
    font-size: $font-size-larger;
    margin-bottom: 3rem;
  }
  .mobile-signin__button {
    margin: 1.5rem auto 0.5rem;
    font-size: 12px;
  }
  .tagline__description-1 {
    @extend .FontRegular;
    font-size: $font-size-medium;
    margin-bottom: 0;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 1);
  }
  .tagline__description-2 {
    @extend.tagline__description-1;
  }
}
.fi-brand__cta-container {
  background-color: rgba(255, 255, 255, 1);
  border-radius: $navigation-button-radius;
  box-shadow: $box-shadow;
  height: 75%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  .cta__header {
    color: $primary-btn-hover-bg-color;
    margin-bottom: 1rem;
  }
  .cta__body {
    color: $primary-btn-hover-bg-color;
  }
  .cta__primary-action-call {
    margin-bottom: 2rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .cta__secondary-action-call {
    margin-top: 2rem;
    text-decoration: none;
    color: $secondary-color;
  }
}

// Carousel & Product Cards
.product-card-container {
  margin-bottom: 1.25rem;
  .submit-btn {
    margin-bottom: 1rem;
  }
}
.carousel-container {
  padding: 0.75rem 1rem;
  // box-shadow: inset 0 7px 22px -5px rgba(54, 54, 54, 0.3);
}

.product-list {
  padding-bottom: 1rem;
}

.card-container-active {
  background-color: $fund-product-card-bg-color;
}
.card-container-active-text {
  color: $fund-product-card-text-color;
}

.product.card {
  padding: 0;
  width: 18rem;
  display: inline-block;
  margin-right: 1rem;
}
.product.card .menu-content {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.product.card .menu-content::before,
.product.card .menu-content::after {
  content: "";
  display: table;
}
.product.card .menu-content::after {
  clear: both;
}
.product.card .menu-content li {
  display: inline-block;
}
.product.card .menu-content a {
  color: #fff;
}
.product.card .menu-content span {
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 10px;
  font-weight: 700;
  font-family: inherit;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.product.card .wrapper {
  height: 320px;
  position: relative;
  overflow: hidden;
  box-shadow: $box-shadow;
  text-align: center;
  white-space: normal;
  .fa-trash {
    cursor: pointer;
    top: 0.5rem;
    right: 0.5rem;
    position: absolute;
    padding: 0.25rem;
    background-color: #fff;
    border-radius: 0.25rem;
    color: $secondary-color;
  }
}
.product.card img {
  width: 100%;
  height: intrinsic;
  vertical-align: top;
}

.product.card .wrapper:hover .data {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.product.card .data {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 190px;
  -webkit-transform: translateY(calc(70px + 1em));
  transform: translateY(calc(70px + 1em));
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.product.card .data .content {
  height: 100%;
  padding: 0.5em;
  position: relative;
  z-index: 1;
}
.product.card .cta-container {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}
.product.card .toggle-button {
  display: none;
}
.product.card .card-title {
  @extend .FontBold;
  font-size: 1em;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 10px;
}
.product.card .text {
  height: 70px;
  margin: 0;
  display: block;
  clear: both;
  text-align: left;
}
.product.card .text .card-link {
  display: block;
  margin-top: 1rem;
}
.see-details-link_color {
  color: $link-color;
}
.see-details-link {
  font-size: 1em;

  text-decoration: underline;
  cursor: pointer;
}

.product.card input[type="checkbox"] {
  display: none;
}
.product.card input[type="checkbox"]:checked + .menu-content {
  -webkit-transform: translateY(-60px);
  transform: translateY(-60px);
}

.product .wrapper {
  background: #ffffff;
  border-radius: 0.25em;
}
.product .added-container,
.product .review-container,
.product .completed-container,
.product .rejected-container,
.product .progress-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c9005e;
  color: #fff;
  padding: 0.25rem;
  box-shadow: 0 7px 22px -5px rgba(54, 54, 54, 0.8);
  border-radius: 0.25em 0 0.25em 0;
  z-index: 1;
  .added-title,
  .review-title,
  .completed-title,
  .rejected-title {
    text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
    @extend .FontBold;
    font-size: $font-size-medium;
  }
}
.product .review-container {
  background-color: $product-status-in-review-bg-color;
}
.product .completed-container {
  background-color: $product-status-is-completed-bg-color;
}
.product .rejected-container {
  background-color: $product-status-is-rejected-bg-color;
}
.product .progress-container {
  background-color: $product-status-in-progress-bg-color;
}
.product .added-container span {
  display: block;
  text-align: center;
}
.product .added-container .added-title {
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
  @extend .FontBold;
  font-size: $font-size-medium;
}
.product .added-container .added-subtitle {
  text-transform: uppercase;
}
.product .added-container .added-subtitle,
.product .added-container .added-description {
  font-size: 12px;
}
.product .content_color {
  background-color: #fff;
}
.product .content {
  box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
  .product-status-container {
    height: 3em;
    width: 3em;
    border-radius: 50%;
    display: inline-block;
  }
  .product-status-text {
    @extend .FontBold;
    font-size: $font-size-extra-small;
  }
  .product-status-in-review-container {
    background-color: $product-status-in-review-bg-color;
    .product-status-in-review-icon::before {
      @extend .FontIcon;
      font-weight: 900;
      color: $default-bg-color;
      content: "\f110";
      margin: 0;
      font-size: $font-size-large;
    }
  }
  .product-status-is-completed-container {
    background-color: $product-status-is-completed-bg-color;
    .product-status-is-completed-icon::before {
      @extend .FontIcon;
      font-weight: 900;
      color: $default-bg-color;
      content: "\f00c";
      font-size: $font-size-large;
    }
  }
  .product-status-is-rejected-container {
    background-color: $product-status-is-rejected-bg-color;
    .product-status-is-rejected-icon::before {
      @extend .FontIcon;
      font-weight: 900;
      color: $default-bg-color;
      content: "\f00d";
      font-size: $font-size-large;
    }
  }
}
.product .title a {
  color: gray;
}
.product .menu-button {
  position: absolute;
  z-index: 999;
  top: 16px;
  right: 16px;
  width: 25px;
  text-align: center;
  cursor: pointer;

  // just hiding for demo for now
  display: none;
}
.product .menu-button span {
  width: 5px;
  height: 5px;
  background-color: gray;
  color: gray;
  position: relative;
  display: inline-block;
  border-radius: 50%;
}
.product .menu-button span::after,
.product .menu-button span::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: currentColor;
  position: absolute;
  border-radius: 50%;
}
.product .menu-button span::before {
  left: -10px;
}
.product .menu-button span::after {
  right: -10px;
}
.product .menu-content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.product .menu-content li {
  width: 100%;
  float: left;
  background-color: #77d7b9;
  height: 60px;
  position: relative;
}
.product .menu-content a {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 24px;
}
.product .menu-content span {
  top: -10px;
}
// END TEST

.marketplace__body-container,
.dashboard-container {
  margin-bottom: 2rem;

  .product-filters {
    border-radius: 0.5rem 0 0 0.5rem;
    border: 1px solid #dfdfdf;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: $box-shadow;
    padding: 1rem;
    margin-right: -15px;
    height: 100%;
  }
  .product-gallery {
    border: 1px solid #dfdfdf;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: $box-shadow;
    padding: 1rem;
    margin-right: -15px;
    height: 100%;
  }
  .summary {
    height: 100%;
  }
  .category-component {
    @extend .FontLight;
    font-size: $font-size-medium;
  }
  .category-component:focus,
  .category-component .row:focus {
    outline: none;
  }

  .has-search {
    margin-top: 1.75rem;
  }
  .has-search .form-control {
    padding-left: 2.375rem;
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    line-height: 1.75rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding: 0.5em;
  }
  .collapse-icon-plus {
    &::after {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f067";
    }
  }
  .collapse-icon-minus {
    &::after {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f068";
    }
  }

  .collapse-icon-caret-up {
    &::after {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f0d8";
    }
  }
  .collapse-icon-caret-down {
    &::after {
      @extend .FontIcon;
      font-weight: 900;
      content: "\f0d7";
    }
  }
  .marketplace__checkbox {
    @extend .FontLight;
    font-size: $font-size-medium;
    margin: 0.5rem;
  }

  .body__vault-card-container {
    color: white;
    .title-container {
      @extend .FontLight;
      font-size: $font-size-large;
      font-weight: bolder;
      word-spacing: 0.2em;
    }
  }
}

.dashboard-container {
  .fi-brand__container {
    background: linear-gradient(
        to right,
        rgba(32, 3, 100, 0.836),
        rgba(34, 185, 231, 0.678)
      ),
      url("../Resources/Images//online-banking.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center left 40%;
    color: white !important;
    height: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem !important;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgb(162, 119, 152);
  }
  .fi-brand__tagline-container {
    width: 50%;
  }
}

.dashboard__body-container {
  .nav-tabs {
    border: none;
  }
  .nav-tabs {
    width: 100%;
  }
  .nav-tabs .nav-item {
    width: 17%;
  }
  .nav-tabs .nav-item .nav-link,
  .nav-tabs .nav-link {
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.253);
    border: none;
    border-radius: 0;
    border-bottom: 4px solid $transparent;
    font-size: 1em;
    text-align: center;
    margin-bottom: 1rem;
    color: #313e49;
    outline: none;
    width: 100%;
  }
  .nav-tabs .nav-item:first-child .nav-link {
    border-radius: 0.5rem 0 0 0;
  }
  .nav-tabs .nav-item:last-child .nav-link {
    border-radius: 0 0.5rem 0 0;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 4px solid $nav-tab-border-color;
    outline: none;
    color: $nav-tab-active-color;
    background-color: $nav-tab-background-color;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
    border-bottom: 4px solid $nav-tab-border-color;
    outline: none;
  }
  .product-card-container {
    border-radius: 0.5rem;
    background-color: white;
    padding: 1rem 2rem;
    overflow-y: hidden;
    min-height: 12rem;
    .form-subtitle {
      .fa-shopping-cart {
        margin-right: 0.5rem;
      }
    }
  }
  .product-row-sub-title {
    display: flex;
    height: 5rem;
  }
  .empty-cart {
    margin: auto;
    @extend .FontBold;
    font-size: $font-size-large;
    opacity: 0.6;
  }
}

.beneficiary-container {
  .beneficiary-Info {
    .edit-btn {
      @extend .common-edit-btn;
    }
    .beneficiary-remove-icon::before {
      color: $secondary-color;
      @extend .FontIcon;
      font-weight: 900;
      content: "\f1f8";
    }
    .info-group {
      .info-group__text {
        @extend .FontLight;
        font-size: $font-size-medium;
        color: black;
        background-color: white;
      }
    }
  }
  .tax-residency-container {
    background-color: $beneficiary-tax-residency-container-bg-color;
  }
  .beneficiary-container__line-separator {
    height: 0;
    background-color: $primary-container-border;
    border: solid 0.1em $primary-container-border;
  }
}

//scroll-bar

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.25rem;
  background: $scroll-bar-track-color;
}
*:hover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background: $scroll-bar-track-color;
}
*::-webkit-scrollbar-thumb {
  background-color: $scroll-bar-hover-color;
  border-radius: 0rem;
  width: 65%;
}
*:hover::-webkit-scrollbar-thumb {
  background-color: $scroll-bar-hover-color;
  border-radius: 1rem;
}

/* Loan Products */
.App {
  .form-control.rate {
    border-bottom: none;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
  }
  .form-label.slider-label {
    @extend .FontBold;
    font-size: $font-size-medium;
  }
  .form-label.slider-label-right {
    padding-left: 0;
    input {
      background-color: inherit;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: inherit;
    }
  }
  input[type="range"] {
    outline: none !important;
    border: none !important;
  }
}
input[type="range"].range-slider.range-slider--primary:not(.disabled):focus::-moz-range-thumb,
input[type="range"].range-slider.range-slider--primary:not(.disabled):active::-moz-range-thumb {
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.5) !important;
}
.loan-amount {
  .input-group-text,
  .input-group-text:hover {
    border: none;
    background-color: transparent;
    border-radius: 0;
    font-size: $font-size-medium;
    @extend .FontBold;
  }
}
input.amount-active,
input.amount-inactive {
  width: 100%;
  text-align: center;
  font-size: $font-size-medium;
  @extend .FontBold;
}
input.amount-active {
  padding: 0.25rem;
  margin: 0;
}
input.amount-inactive {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  padding: 0;
  color: #000;
}
.footnotes {
  margin-bottom: 0.25rem;
}
.loan-details-container {
  .alert-success {
    background-color: #06688b;
    border: none;
    //box-shadow: 0 4px 10px -5px black;
    //margin-bottom: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 0;
    .form-subtitle {
      color: #fff;
      text-align: center;
      font-size: $font-size-larger;
      margin-bottom: 0.5rem;
      display: block;
    }
    .form-description {
      font-size: $font-size-large;
    }
  }
  .product-card-container {
    border-radius: 0.5rem;
    background-color: white;
    padding: 1rem 2rem;
    height: 100%;
  }
  .custom-radios {
    .product-list {
      padding-bottom: 0;
      .btn[value="chequing"]::before {
        top: 0;
        right: 0;
        left: revert;
        font-size: 1em;
      }
    }
  }
}

textarea.form-control {
  border-radius: 0.25rem !important;
  border: 1px solid grey !important;
  min-height: 200px;
}
textarea.form-control:hover {
  border: 1px solid grey !important;
}
.fas.fa-exchange-alt {
  -moz-transition: all 2s linear;
  -webkit-transition: all 2s linear;
  transition: all 0.3s linear;
}
// CHRIS TEMP HACK FOR DEMO
.disabled {
  opacity: 0.6;
}

.info-btn.add-btn {
  padding: 0.375rem 0.75rem;
  text-transform: none;
  border-radius: 0.25rem;
  font-size: $font-size-small;
}
.details-section.summary-tab {
  border: 1px solid #d9d9dc;
  box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 0.25);
}

// CHRIS: HACK FOR DEMO
.skip-funding {
  font-size: $font-size-medium;
  color: $link-color !important;
  @extend .FontMedium;
  text-decoration: underline;
  cursor: pointer;
}

/** 
  * --------------------
  * Overrides - Chris
  * --------------------
*/

/**
 * ----------------------------------------
 * Animations - Chris
 * ----------------------------------------
 */

header {
  -webkit-animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s
    both;
  animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s both;
}
.info-banner,
.welcome-back {
  -webkit-animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s
    both;
  animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

.navigation-bar__back-btn {
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    0.75s both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.75s both;
}
.main-container__body__left-column,
.product-filters {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}
.main-container__body__middle-column,
.product-gallery {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    1.3s both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3s both;
}
.vault-container {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    1.5s both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
}
.drawer-content-container {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    0.5s both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}

@media only screen and (max-width: $breakpoint-desktop) {
  .App {
    .vault-container,
    .dropdown-menu {
      -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        0.5s both;
      animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s
        both;
    }
    .dropdown-menu.show.global_nav_drawer,
    .vault-container {
      overflow-y: auto;
    }
    .vault-container,
    .dropdown-menu {
      border-radius: 0;
      border-color: $vault-header-bg-color;
      background-color: rgba(255, 255, 255, 1);
      background-color: $backdrop-color;
    }
    .dropdown-menu.show,
    .vault-container {
      z-index: 1040 !important;
      border: none;
      top: 4rem !important;
      width: 100% !important;
      position: fixed !important;
      border-radius: 0;
      height: 100vh !important;
      padding: 0;
      padding-bottom: 7rem;
    }
    .global_nav_drawer__inner-container,
    .vault-container__inner-container {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      margin-top: 1.5rem;
      border: 1px solid #fff;
    }
    .vault-container__inner-container {
      position: initial;
    }
    .vault-header__container,
    .dropdown-menu.show .vault-header__container {
      width: 100%;
      border-radius: 0;
      padding: 1rem;
    }
    .dropdown-item {
      color: $link-color;
      padding: 1rem 1.5rem 1rem 1rem;
    }

    .product.card {
      .data {
        .content {
          overflow: hidden;
          .card-title {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      .toggle-button {
        //background-color: red;
        display: block;
        position: relative;
        top: -1.5rem;
        margin-left: auto;
        margin-right: auto;
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        transition: all;
        .fas {
          margin-top: 1.25rem;
          color: $secondary-color;
        }
      }
    }
  }
  .product.card .wrapper:hover .toggle-button {
    .fas {
      transform: rotate(180deg);
      outline: none;
      color: $primary-color;
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop) {
  .vault-container {
    .vault-header__container {
      h4.form-subtitle {
        .close {
          display: none;
        }
      }
    }
  }
}

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
// CHRIS: @Media Enquiries

// CHRIS BUG

@media only screen and (max-width: $breakpoint-small-mobile) {
  .App {
    .forgot-link,
    .checkbox-text {
      font-size: $font-size-small !important;
    }
  }
  .dashboard__body-container {
    .nav-tabs .nav-item {
      font-size: 90%;
    }
    .product-card-container {
      padding: 1rem 1rem 1rem 1rem;
    }
  }
  .stepper-form-title {
    width: 184px;
  }
  .loan-details-container .alert-success .form-subtitle {
    font-size: $font-size-large;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .App {
    .rowblock_gettingstarted {
      margin-right: -0.9375em;
      margin-left: -0.9375em;
      display: block;
    }

    .spacesteps {
      justify-content: space-between;
      padding: inherit;
    }
  }
  // CHRIS NOTE: Hide mobile response for now
  // .product.card {
  //   width: 16rem;
  //   .wrapper {
  //     height: 190px;
  //   }
  // }

  .fas.fa-exchange-alt {
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    position: relative;
    bottom: -0.75rem;
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .terms-and-conditions-container {
    .info-tile-container {
      // col-md-4 hack as for some reason the info tiles are coded to use the same component as the progressive disclosure drawers???
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  .dashboard__body-container {
    .nav-tabs .nav-item {
      width: 20%;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .App {
    .custom-radios {
      .product-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem 1rem;
        .btn[value] {
          min-height: 7rem;
        }
      }
    }
  }
  .fi-brand__tagline-container .tagline__title-1 {
    font-size: 2em;
    margin-bottom: 1.5rem;
  }
  .fi-brand__tagline-container .tagline__title-2 {
    font-size: 1.5em;
    margin-bottom: 0;
    text-align: center;
  }
  .dashboard__body-container {
    .product-card-container {
      .carousel-container {
        padding-left: 0em;
      }
    }
    .nav-tabs {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .nav-tabs .nav-item {
      width: 50%;
    }
    .nav-tabs .nav-item .nav-link,
    .nav-tabs .nav-link {
      width: 100%;
      height: 2.6rem;
      background: $custom-radio-unselected-bg-color;
      border: 1px solid $custom-radio-unselected-border-color;
      font-size: $font-size-medium;
      color: $custom-radio-unselected-color;
    }
    .nav-tabs .nav-item:first-child .nav-link {
      border-radius: 0.5rem 0 0 0.5rem;
    }
    .nav-tabs .nav-item:last-child .nav-link {
      border-radius: 0 0.5rem 0.5rem 0;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      height: 2.6rem;
      background-color: $custom-radio-selected-bg-color;
      box-shadow: $custom-radio-box-shadow-inset;
      border: 1px solid $custom-radio-selected-border-color;
      font-size: $font-size-medium;
      color: $custom-radio-selected-color;
      border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
  }
  .product.card .wrapper {
    background-color: #fff;
    // hide for responsive demo for now
    // height: 176px;
    position: relative;
    overflow: hidden;
    box-shadow: $box-shadow;
    .data {
      height: 200px;
      .form-subtitle {
        margin-top: 0;
        margin-bottom: 0;
      }
      .cta-container {
        position: relative;
        bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .App {
    .fi-brand__container {
      height: auto;
    }
    .fi-brand__tagline-container {
      width: 100%;
      .tagline__title {
        font-size: 2em;
      }
      .tagline__description-1,
      .tagline__description-2 {
        display: none;
      }
    }
    .fi-brand__cta-container {
      display: none;
    }
    header {
      // header has 2 parts breadcrumbs row and header-container
      .header-container {
        .page-header {
          display: none;
        }
      }
      // .breadcrumb-container {
      //   display: none;
      // }
    }

    .marketplace__body-container,
    .dashboard-container {
      .product-filters {
        border-radius: 0.24rem;
        margin-bottom: 1.5rem;
        height: auto;
        margin-right: 0;
      }

      .product-gallery {
        border-radius: 0.24rem;
        margin-bottom: 1.5rem;
        height: auto;
        margin-right: 0;
      }
      .summary {
        box-shadow: $box-shadow;
        height: 100%;
      }
    }

    .content-wrapper {
      .main-container {
        padding-top: 0rem;

        .main-container__body {
          .main-container__body__left-column {
            padding-right: 15px;
            > * {
              background-color: hsla(0, 0%, 100%, 0.85);
              box-shadow: $box-shadow;
              border: $border $primary-container-border;
              border-radius: 0.5em;
              margin-bottom: 1rem;
              height: auto;
            }
          }
          .main-container__body__middle-column {
            > * {
              padding-top: 1rem;
              padding-bottom: 1rem;
              background-color: $info-banner-bg;
              color: $default-bg-color;
              box-shadow: $box-shadow;
              border: $border $primary-container-border;
              border-radius: 0.5em;
              height: auto;
            }
            .info-title {
              color: #fff;
            }
          }
        }
      }
    }

    .form-container {
      padding: 0;
    }
    .form-title-register,
    .form-title-signin,
    .form-title-verification,
    .form-title-password,
    .form-title-existing,
    .form-title-forgot-password,
    .form-title-terms {
      padding: 1rem 0rem;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .App {
    .container {
      max-width: 96% !important;
    }
  }
}
@media only screen and (max-width: $breakpoint-mobile) {
  .App {
    .custom-radios {
      .product-list {
        grid-template-columns: repeat(1, 1fr);
        gap: 0rem;
        .btn[value] {
          min-height: 7rem;
        }
      }
    }
  }
}

// Product Modal
.modal-content.product {
  // border: none;
  .added-container {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    height: 100%;
  }
  .modal-header h4 {
    display: flex;
  }
  .modal-header__title {
    position: relative;
    display: inline-block;
    .form-title,
    .form-description {
      display: block;
    }
  }
  .modal-footer {
    justify-content: center;
    .alert-warning {
      padding: 1rem;
    }
    .tertiary-btn {
      background-color: $default-label;
      border: $border $secondary-btn-border-color;
      border-radius: $button-radius-normal;
      @extend .FontBold;
      font-size: $font-size-medium;
      color: $secondary-btn-color;
      cursor: pointer;
      text-transform: uppercase;
      line-height: 1.62;
      padding: 0.76543em 2em;
    }
    .tertiary-btn:active,
    .tertiary-btn:hover {
      background-color: #33393d;
      color: $secondary-btn-color;
    }
    .tertiary-btn:focus {
      box-shadow: none;
    }
  }
}

.version-num {
  position: relative;
  text-align: right;
  font-size: 1em;
  font-weight: 700;
  color: transparent;
}

span.progress-message {
  width: 200px;
  margin-left: -75px;
  display: flex;
  color: #fff !important;
  font-size: 1em;
  line-height: 1.6em;
  font-family: "Helvetica";
}

.sigPad {
  align-self: center;
  width: 100%;
  height: 150px;
  border: 2px;
}

.sigContainer {
  border: 2px solid black;
  margin: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.datepicker {
  width: 100%;
}
.datepicker[readonly] {
  padding: 0.375rem 0.75rem;
  background-color: #e9ecef;
}

.new-line {
  white-space: pre-line;
}

.noFormatInput {
  text-transform: none !important;
}

.capitalize {
  text-transform: uppercase !important;
}
