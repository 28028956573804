.fund-account__container {
  height: 100%;
  .fund-account-header {
    margin-bottom: 6%;
    padding: 1em 0em 1em 1em;
    display: flex;

    .long-left-arrow::before {
      top: 1.6em;
      left: 2.5em;
    }

    .long-right-arrow::before {
      top: 3em;
      left: 4.5em;
    }
  }

  .fund-account-item__wrapper {
    .fund-account-item {
      margin: 4%;
    }
  }
}
