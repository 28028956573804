@import "../../Styles/color.scss";
@import "../../Styles/styleConstants.scss";
@import "../../Styles/fonts.scss";

.drawer-content-container {
  .form-control,
  .form-label {
    font-size: $font-size-small;
  }
}

.add-another-submit-btn:enabled,
.add-another-submit-btn:disabled {
  background-color: $primary-btn-bg-color;
  border-radius: $button-radius-normal;
  color: $primary-btn-color;
  border-color: $primary-btn-border-color;
  @extend .FontBold;
  font-size: $font-size-medium;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.62;
  padding: 0.76543em 2em;
}
.add-another-submit-btn:active:enabled,
.add-another-submit-btn:hover:enabled {
  background-color: $primary-btn-hover-bg-color;
  border-color: $primary-btn-border-color;
  color: $primary-btn-color;
}

.add-another-submit-btn:focus:enabled {
  box-shadow: none;
}

.add-another-submit-btn:disabled {
  background-color: $primary-btn-bg-color;
  color: $primary-btn-color;
  cursor: default;
}

.add-another-submit-btn:active:disabled,
.add-another-submit-btn:hover:disabled {
  color: $primary-btn-color;
  cursor: default;
}

.delete_icon {
  cursor: pointer;
}

.error-msg-height {
  height: 2.6vh;
}

.text-responsive {
  font-size: calc(30% + 1vw + 1vh);
}
