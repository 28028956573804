@import "../../Styles/fonts.scss";
@import "../../Styles/color.scss";
.vault-container {
  .vault-card-body {
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    .vault-container__card {
      margin-top: 0.75em;
      padding: 0.5em;
      display: flex;
      height: fit-content;
      position: relative;
      .vault-container__card__header {
        height: 4em;
        margin-right: 1rem;
        .product-icon-container {
          height: 4em;
          .product-icon-container__img {
            height: 100%;
            border-radius: 0.25rem;
          }
        }
        .vault-container__card__body {
          flex: 1 1;
          padding: 0.5em;
          position: relative;
          .vault-container__card__body__title-container {
            white-space: nowrap;
            width: 7em;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
          }

          .vault-container__card__body__subtitle {
            width: 85%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 2em;
          }
        }
      }
    }
  }
}

.vault-container
  .vault-card-body
  .vault-container__card
  .vault-container__card__body
  .delete-button__icon:before {
  font-weight: 600;
  content: "\f1f8";
  top: -0.65em;
  right: 0;
  position: absolute;
  font-size: 0.75em;
}

$breakpoint-tablet: 768px;
@media only screen and (max-width: $breakpoint-tablet) {
  .vault-card {
    margin-left: 0.65em;
    margin-right: 0.65em;
  }
}
